import React, { useEffect, useMemo, useState } from 'react'
import Switch from '../../../utility/switch'
import { Provider, useSelector } from 'react-redux'
import { selectInsuranceQuoteInterstitialState, setStartingQuote } from '../slice/slice'
import { useGetStartingQuote } from '../hooks'
import { store } from 'components/insurance/store/store'
import MonetaryAmount from '../components/MonetaryAmount'
import GetQuoteButton from '../button'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'

import chubbLogo from '/app/assets/images/insurance/logos/chubb.png'
import lemonadeLogo from '/app/assets/images/insurance/logos/lemonade.png'
import loadingIcon from '/app/assets/images/insurance/icons/loader.gif'

const ServerError = () => {
  return (
    <div className="quote-server-error">
      <div className="dome-p2 dome-color-dark-grey">The Chubb rating service is unavailable.</div>
      <div className="dome-p3-lt dome-color-dark-grey" style={{ fontSize: '10px' }}>
        If this problem persists, please contact{' '}
        <a href="mailto:info@domecile.com" className="dome-link dome-link-aqua" style={{ fontSize: '10px' }}>
          info@domecile.com
        </a>
        .
      </div>
    </div>
  )
}

const QuoteLoading = () => {
  return (
    <div className="quote-loading dome-d-flex dome-align-center">
      <div className="dome-loading-img">
        <img src={loadingIcon} width={95} height={95} />
      </div>
      <div>
        <div className="dome-p2 dome-color-dark-grey">We’re still working on the estimate for this property.</div>
        <div className="dome-p3-lt dome-color-dark-grey">In the meantime, continue to the next step.</div>
      </div>
    </div>
  )
}

function QuoteResult({ premium, building, insuranceType, all_requirements, started, onInterstitialOpen }) {
  const parsedPremium = parseInt(premium)
  const monthlyAmount = (isFinite(parsedPremium) ? parsedPremium : 0) / 12
  const required = all_requirements?.[`requires_${insuranceType}`]

  return (
    <div className="dome-d-flex dome-align-center dome-justify-between">
      <div className="dome-d-flex dome-align-baseline dome-flex-column dome-gap6">
        <div className="dome-p1 dome-d-flex dome-align-baseline">
          <div className="dome-h2 dome-color-dark-green dome-d-flex dome-align-start" style={{ lineHeight: 1 }}>
            <MonetaryAmount amount={monthlyAmount} />
          </div>
          <span>/month</span>
        </div>
        <div className="dome-p2 dome-color-med-grey" style={{ flexBasis: '55%' }}>
          {required ? 'estimate based on requirements' : "estimate based on insurer's recommendations"}
        </div>
      </div>
      <GetQuoteButton building={building} insuranceType={insuranceType} started={started} onInterstitialOpen={onInterstitialOpen} />
    </div>
  )
}

const EstimatedQuoteResult = ({ building, insuranceType, packageId }) => {
  const dispatch = useDispatch()
  const {
    quote,
    fullScreenLoading,
    serverError,
  } = useSelector(selectInsuranceQuoteInterstitialState)

  const [loading, setLoading] = useState(false)
  const [started, setStarted] = useState(false)

  const startingQuote = useGetStartingQuote(building, insuranceType)

  const [premium, writingCompany] = useMemo(() => {
    if (quote && quote.insuranceType === insuranceType && quote.buildingId === building?.id && quote.premium) {
      return [quote.premium, quote.writingCompany]
    }

    if (startingQuote) {
      return [startingQuote.premium, startingQuote.writingCompany]
    }

    return [null, null]
  }, [quote, insuranceType, startingQuote, building?.id])

  useEffect(() => {
    if (building?.id && !premium) setStarted(true)
  }, [building?.id])

  useEffect(() => {
    if (fullScreenLoading.visible) {
      setLoading(fullScreenLoading.visible)
    }

    if (!fullScreenLoading.visible && started) {
      setLoading(false)
    }
  }, [fullScreenLoading, started])

  useEffect(() => {
    dispatch(setStartingQuote(isEmpty(startingQuote) ? null : startingQuote))
  }, [dispatch, startingQuote])

  const onInterstitialOpen = () => setStarted(true)

  const renderQuoteMain = () => {
    if (serverError) {
      return <ServerError />
    }

    if (loading || !premium) {
      return <QuoteLoading />
    }

    if (premium) {
      return (
        <QuoteResult
          premium={premium}
          insuranceType={insuranceType}
          all_requirements={building?.parsed_all_requirements}
          building={building}
          started={started}
          onInterstitialOpen={onInterstitialOpen}
        />
      )
    }
  }

  return (
    <div className='dome-d-flex dome-flex-column dome-gap12'>
      { renderQuoteMain() }
      <Footer writingCompany={writingCompany} />
    </div>
  )
}

const Header = ({ onChange }) => {
  return (
    <div className='dome-d-flex dome-align-center dome-justify-between'>
      <div className='dome-p1 dome-color-dark-green'>need insurance coverage?</div>
      <Switch variant='outlined' offText='owner' onText='renter' onChange={onChange} />
    </div>
  )

}

const Content = ({ building, insuranceType }) => {
  return (
    <EstimatedQuoteResult building={building} insuranceType={insuranceType} />
  )
}

const Footer = ({ writingCompany }) => {
  return (
    <div className='dome-d-flex dome-align-center dome-justify-between'>
      {writingCompany && (
        <em className="insurance-written-by dome-color-med-grey dome-p4">Insurance underwritten by: {writingCompany}</em>
      )}
      <div className="logos dome-d-flex dome-align-center dome-gap6">
        <img src={chubbLogo} style={{ height: '6px', objectFit: 'contain' }} />
        <img src={lemonadeLogo} style={{ height: '10px', objectFit: 'contain' }} />
      </div>
    </div>
  )
}

const InstantQuoteBuilding = ({ building }) => {
  const [insuranceType, setInsuranceType] = useState('homeowners')

  const handleToggleInsuranceType = (on) => {
    setInsuranceType(on ? 'renters' : 'homeowners')
  }

  return (
    <div
      className="row card card-section card-header"
      style={{ padding: '1px', background: 'var(--green-linear-gradient)' }}
    >
      <div
        className="instant-quote-building dome-d-flex dome-flex-column dome-gap12 dome-bg-white"
        style={{ padding: '18px', borderRadius: '4px' }}
      >
        <Header onChange={handleToggleInsuranceType} />
        <Content building={building} insuranceType={insuranceType} />
      </div>
    </div>
  )
}

export default (props) => (
  <Provider store={store}>
    <InstantQuoteBuilding {...props} />
  </Provider>
)
